import { imageProps } from "./imageProps";

export const Carsousel_HOME_ARRAY = () => {
  return [
    {
      src: imageProps?.carousel1?.src,
      alt: imageProps?.carousel1?.alt,
      height: imageProps?.carousel1?.height,
      width: imageProps?.carousel1?.width,
    },
    {
      src: imageProps?.carousel2?.src,
      alt: imageProps?.carousel2?.alt,
      height: imageProps?.carousel2?.height,
      width: imageProps?.carousel2?.width,
    },
    {
      src: imageProps?.carousel3?.src,
      alt: imageProps?.carousel3?.alt,
      height: imageProps?.carousel3?.height,
      width: imageProps?.carousel3?.width,
    },
    {
      src: imageProps?.carousel4?.src,
      alt: imageProps?.carousel4?.alt,
      height: imageProps?.carousel4?.height,
      width: imageProps?.carousel4?.width,
    },
    {
      src: imageProps?.carousel5?.src,
      alt: imageProps?.carousel5?.alt,
      height: imageProps?.carousel5?.height,
      width: imageProps?.carousel5?.width,
    },
    {
      src: imageProps?.carousel6?.src,
      alt: imageProps?.carousel6?.alt,
      height: imageProps?.carousel6?.height,
      width: imageProps?.carousel6?.width,
    },
  ];
};
export const Members_ARRAY = () => {
  return [
    {
      img: imageProps?.memberClaude?.src,
      title: "Claude Delatter - Zang",
      text: (
        <>
          Na een passage bij verschillende kleine bandjes werd Claude Delatter
          door zijn deelname aan de Hennie Huysman Soundmixshow in 1989 (KRO –
          Nederlandse Televisie), zijn finaleplaats in de eerste
          VTM-Soundmixshow in 1989, tweemaal met een nummer van Billy Joel en
          tenslotte met de SABAM-prijs “Beste Belgische Vertolker” met zijn
          eigen nummer “Water bij de wijn” in de toen populaire “Baccarabeker”
          in het Casino van Middelkerke, en dat in een druk deelnemersveld met
          o.a. Beverly Jo Scott, Mama’s Jasje en Sanne. Door zijn koppig
          vasthouden aan zijn eigen wil sprongen verschillende concrete
          bijna-deals met major platenfirma’s af, en besliste hij, enigszins
          ontgoocheld, semiprofessioneel met muziek bezig te blijven.
          <br />
          <br />
          In 1993 kwam hij op de radar van Chris Sommers, die een nieuwe zanger
          zocht voor “The Crowd”, eerst tijdelijk, maar hij geraakte en wilde er
          niet meer weg. Samen vormen ze sedertdien de ruggengraat bij “The
          Crowd” en hebben ze elkaar ook gevonden bij dit B-Floyd. Claude
          Delatter was en is vooral fan van het album “The Wall”, maar kan de
          andere nummers van Pink Floyd ook wel smaken. Hij startte het project
          “HEAVIER” (Power Rock Coverband) op in 2024, waarin hij ook Chris
          Somers, Sven Godyn en neef Stefaan Vansteenkiste, allen muzikanten van
          B-Floyd in betrok.
        </>
      ),
    },
    {
      img: imageProps?.memberChris?.src,
      title: "Chris Somers - Gitaren",
      text: (
        <>
          Chris begon zoals velen bij een aantal bandjes op school, maar had in
          1980 succes met de groep “EX-HOLE” en bracht als BELPOP-ontdekking 1
          LP en 4 Single vinyls uit, met leuke optredens o.a. in de
          Beursschouwburg, de legendarische “Vooruit” in Gent, “De Brielpoort”
          in Deinze en tal van Radio en Televisieoptredens en een liveoptreden
          voor Radio 2.
          <br />
          <br />
          Hij won met “LOOKIN' GLASS” (voorloper van Family Duck) de rock
          wedstrijd van Joepie en Het Laatste Nieuws (Rock 83).
          <br />
          <br />
          Daarna deed hij tussen 1984 en 1987 met zijn nieuwe band “MODERN
          VISION” 125 optredens (waaronder voorprogramma van OMD in “de
          Brielpoort”), kreeg hij veel Airplay en een 10-tal tv-optredens in NL
          en België en bracht 2 mini-lp's en 2 singles uit. In het
          Radioprogramma “VRIJAF” met Gust De Coster speelden ze een liveshow.
          <br />
          <br />
          In augustus 1987 richtte Chris de Classic Rock Coverband “THE CROWD”
          op (toen nog onder de neem “Lonely in the Crowd”, in 1992 afgekort tot
          “The Crowd”) waarmee hij sindsdien meer dan 1000 optredens deed, samen
          met zanger Claude Delatter, met wie hij ook bij B-FLOYD het podium
          deelt. Chris is fan van David Gilmour, en kon zijn muzikale “pink
          Floyd”-ei kwijt bij B-Floyd waarbij hij één van de bezielers is samen
          met Jacques “James” Audenaert, de drummer van zowel “The Crowd” als
          “B-Floyd”.
        </>
      ),
    },
    {
      img: imageProps?.memberJacques?.src,
      title: "Jacques 'James' Audenaert - Drums & achtergrondzang",
      text: (
        <>
          Begon te drummen op zijn 15e en heeft er sindsdien niet meer mee
          opgehouden.
          <br />
          <br />
          Speelde in tal van bands en op heel wat opnames (pop, rock, funk,
          soul, ... zelfs symfonische rock in een ver verleden).
          <br />
          <br />
          Als hij geen drums speelt, vindt James het heerlijk om gitaar te
          spelen en te zingen, of om te fietsen, of om een leuk restaurantje te
          bezoeken…
        </>
      ),
    },
    {
      img: imageProps?.memberSven?.src,
      title: "Sven Godyn - Keyboards & Visuals",
      text: (
        <>
          Sven-T-Bold speelt al heel zijn leven keyboards bij o.a. The Crowd, en
          is er nog steeds de doublure van de huidige vaste keyboardspeler. Nu
          eens wel, dan eens niet dus, maar hij vindt er nog steeds zijn steun
          bij deze bende olijke muzikale vrienden.
          <br />
          <br />
          Hij zette zijn eerste muzikale stappen bij “The Midges”, het
          huisorkest van Marijn “Boma” Devalck. Daar werd hij gescout door
          wijlen “Den John” (John Premereur) om te komen spelen bij ‘L’ Avanti”
          waar Chris Somers van “The Crowd” al snel deze spring in het veld
          opmerkte en hem inlijfde bij “The Crowd”. Met “Heavier” keerde hij
          terug naar zijn roots: het betere en zeer stevige werk van de
          beginjaren van The Crowd!
          <br />
          <br />
          Sven breidde zijn muzikale carrière verder uit bij The Xperience, VIP
          Partyband, Das Rock, Chezkit, Tribute band Depeche More, maar ook nog
          tientallen andere leuke bands. Bij B-FLOYD speelt hij keyboards,
          verzorgt hij mee de backing-Vocals en staat hij in voor de
          fantastische Visuals en video’s.
        </>
      ),
    },
    {
      img: imageProps?.memberStefaan?.src,
      title: "Stefaan Vansteenkiste - Gitaren & achtergrondzang",
      text: (
        <>
          Toen op jonge leeftijd bleek dat mijn met veel geduld in elkaar
          gefiguurzaagde gitaar niet bestand was tegen de veel te hoge spanning
          van ontrafelde remkabels wist ik het. Ik zou mijn droom beter kopen
          zodat de “echte” speeltijd kon beginnen. Het werd een akoestische
          gitaar en ja, nu nog steeds in goede staat.
          <br />
          <br />
          Later richtte mijn neef, Claude Delatter, met enkele jeugdige
          muzikanten diverse groepjes op met mij als ritmegitarist. Zonder
          theoretische opleiding evolueerde mijn gitaartechniek en muzikale
          kennis in positieve zin dankzij veel oefenen en de goede tips van de
          “wel” geschoolde leden uit die jeugdgroepjes. Enkel maar goede
          herinneringen heb ik aan Guppy Food, Think About It & Springlevend.
          <br />
          <br />
          Nog iets later werd ik gitarist bij een Bigband waar ik ondanks mijn
          beperkte muziekkennis de ritmesectie mocht aanvullen. Dezelfde soms
          moeilijke akkoorden 10 keer sneller spelen was daarna het geval in een
          Dixieland nevengroepje. In die periode waren de weken en weekends op
          de duur zodanig goed gevuld dat ik toch even de pauzeknop moest
          induwen.
          <br />
          <br />
          Tributeband “The Shadows Sound” werd nog iets later het volgende
          project waar ik enkele jaren mocht deel van uitmaken. Zo goed als
          mogelijk de echte sound van de Shadows spelen was ons target.
          <br />
          <br />
          Toen Claude mij post-corona polste naar mijn interesse in een nieuwe
          tributeband rond Pink Floyd kon ik uiteraard geen neen zeggen. Een
          nieuwe wereld ging voor mij open en de “Kozen” en zijn gitaren waren
          er klaar voor.
          <br />
          <br />
          De menige repetities zowel @Home als met de B-Floyd vrienden,
          resulteerden in schitterende momenten en enkele degelijke optredens op
          diverse podia. Maar de honger naar nog meer muziek spelen blijft en
          samen met enkele bevriende muzikanten kwam er eind 2023 een nieuw
          project, deze keer iets “Heavier”. Let’s go for it!
        </>
      ),
    },
    {
      img: imageProps?.memberFilip?.src,
      title: "Filip Vanderputten - Bas",
      text: <>Geen bio</>,
    },
  ];
};
export const Media_ARRAY = () => {
  return [
    {
      src: imageProps?.media1?.src,
      width: 350,
      height: 200,
    },
    {
      src: imageProps?.media3?.src,
      width: 300,
      height: 200,
    },
    {
      src: imageProps?.media4?.src,
      width: 300,
      height: 200,
    },
    {
      src: imageProps?.media5?.src,
      width: 300,
      height: 200,
    },
    {
      src: imageProps?.media6?.src,
      width: 300,
      height: 200,
    },
    {
      src: imageProps?.media7?.src,
      width: 300,
      height: 200,
    },
  ];
};

export const Reference_ARRAY = () => {
  return [
    {
      src: imageProps?.reference1?.src,
      width: 594,
      height: 841,
    },
    {
      src: imageProps?.reference2?.src,
      width: 594,
      height: 841,
    },
  ];
};
