import { db } from "./firebaseConfig";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";

import {
  NotifyError,
  NotifySuccess,
} from "../components/notifications/toastify";
import { ConvertTimestampToDate } from "../fuctions/dates";

const concertsCollection = collection(db, "concerts");
const optionsCollection = collection(db, "options");

// GET CONCERTS
export const getConcerts = async () => {
  try {
    const concertSnapshot = await getDocs(concertsCollection);

    const concertList = concertSnapshot?.docs?.map((doc) => ({
      id: doc?.id,
      ...doc.data(),
    }));
    return concertList;
  } catch (e) {
    NotifyError(e?.code);
  }
};

// ADD CONCERTS
export const addConcert = async (concert) => {
  try {
    const formattedConcert = {
      ...concert,
      start_date: Timestamp.fromDate(new Date(concert?.start_date)),
      startTime: Timestamp.fromDate(
        new Date(`${concert?.start_date}T${concert?.startTime}:00`)
      ),
      endTime: Timestamp.fromDate(
        new Date(`${concert?.start_date}T${concert?.endTime}:00`)
      ),
    };

    await addDoc(concertsCollection, formattedConcert);
    NotifySuccess("Concert succesvol toegevoegd");
  } catch (e) {
    NotifyError(e?.code);
  }
};

// UPDATE CONCERTS
export const updateConcert = async (id, updatedConcert) => {
  const concertDoc = doc(db, "concerts", id);
  try {
    const formattedConcert = {
      ...updatedConcert,
      start_date: Timestamp.fromDate(new Date(updatedConcert?.start_date)),
      startTime: Timestamp.fromDate(
        new Date(
          `${updatedConcert?.start_date}T${updatedConcert?.startTime}:00`
        )
      ),
      endTime: Timestamp.fromDate(
        new Date(`${updatedConcert?.start_date}T${updatedConcert?.endTime}:00`)
      ),
    };

    await updateDoc(concertDoc, formattedConcert);
    NotifySuccess("Concert succesvol gewijzigd");
  } catch (e) {
    NotifyError(e?.code);
  }
};

// DELETE CONCERTS
export const deleteConcert = async (id) => {
  const concertDoc = doc(db, "concerts", id);
  try {
    await deleteDoc(concertDoc);
    NotifySuccess("Concert succesvol verwijderd");
  } catch (e) {
    NotifyError(e?.code);
  }
};

// GET CONCERT OPTIONS
export const getConcertOptions = async () => {
  try {
    const optionSnapshot = await getDocs(optionsCollection);
    const optionList = optionSnapshot?.docs?.map((doc) => ({
      id: doc?.id,
      ...doc?.data(),
    }));
    return optionList;
  } catch (e) {
    NotifyError(e?.code);
  }
};

// ADD CONCET OPTION
export const addConcertOption = async (option) => {
  try {
    const formattedOption = {
      ...option,
      start_date: Timestamp.fromDate(new Date(option?.start_date)),
      startTime: Timestamp.fromDate(
        new Date(`${option?.start_date}T${option?.startTime}:00`)
      ),
      endTime: Timestamp.fromDate(
        new Date(`${option?.start_date}T${option?.endTime}:00`)
      ),
    };

    await addDoc(optionsCollection, formattedOption);
    NotifySuccess("Concertoptie succesvol toegevoegd");
  } catch (e) {
    NotifyError(e?.code);
  }
};

// UPDATE CONCERT OPTION
export const updateConcertOption = async (id, updatedOption) => {
  const optionDoc = doc(db, "options", id);
  try {
    const formattedOption = {
      ...updatedOption,
      start_date: Timestamp.fromDate(new Date(updatedOption?.start_date)),
      startTime: Timestamp.fromDate(
        new Date(`${updatedOption?.start_date}T${updatedOption?.startTime}:00`)
      ),
      endTime: Timestamp.fromDate(
        new Date(`${updatedOption?.start_date}T${updatedOption?.endTime}:00`)
      ),
    };

    await updateDoc(optionDoc, formattedOption);
    NotifySuccess("Concertoptie succesvol gewijzigd");
  } catch (e) {
    NotifyError(e?.code);
  }
};

// DELETE CONCERT OPTION
export const deleteConcertOption = async (id) => {
  const optionDoc = doc(db, "options", id);
  try {
    await deleteDoc(optionDoc);
    NotifySuccess("Concertoptie succesvol verwijderd");
  } catch (e) {
    NotifyError(e?.code);
  }
};

// REAL TIME UPDAT FOR CONCERT
export const onConcertsChange = (callback) => {
  try {
    return onSnapshot(concertsCollection, (snapshot) => {
      const concertList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedEvents = concertList?.sort((a, b) => {
        const dateA = a?.start_date
          ? ConvertTimestampToDate(a?.start_date)
          : new Date();
        const dateB = b?.start_date
          ? ConvertTimestampToDate(b?.start_date)
          : new Date();
        return dateA - dateB;
      });

      callback(sortedEvents);
    });
  } catch (e) {
    NotifyError(e?.code);
  }
};

// REAL TIME UPDAT FOR CONCERT OPTION
export const onConcertOptionsChange = (callback) => {
  try {
    return onSnapshot(optionsCollection, (snapshot) => {
      const optionList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(optionList);
    });
  } catch (e) {
    NotifyError(e?.code);
  }
};
