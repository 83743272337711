import React from "react";
import { Button, Spinner } from "react-bootstrap";

export default function LoadingButton({ isLoading, text, type, className }) {
  return (
    <Button className={className} type={type} disabled={isLoading}>
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{ marginRight: "6px" }}
          />
          <span>{text}</span>
        </>
      ) : (
        text
      )}
    </Button>
  );
}
