import React from "react";
import { Gallery } from "react-grid-gallery";
import { Card } from "react-bootstrap";

export default function MediaCard({ images }) {
  return (
    <Card className="mb-3 media_card">
      <Card.Body>
        <Gallery
          images={images}
          enableImageSelection={false}
          rowHeight={360}
          maxRows={3}
        />
      </Card.Body>
    </Card>
  );
}
