import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaYoutube } from "react-icons/fa";

import NavLogo from "../../images/navLogo.png";
import ProgressiveImage from "../images/progressiveImage";

export default function Footer() {
  return (
    <footer className="footer ">
      <Container>
        <Row className="smallfs align-items-center">
          <Col
            md={3}
            className="d-flex justify-content-center justify-content-lg-start"
          >
            <ProgressiveImage
              src={NavLogo}
              width="auto"
              height="55"
              className="p-3"
              alt="Logo"
            />
          </Col>
          <Col md={6} className="text-center p-3">
            <div>
              Copyright &copy; {new Date().getFullYear()} - B-floyd - All rights
              reserved
            </div>
          </Col>
          <Col
            md={3}
            className="d-flex justify-content-center justify-content-lg-end"
          >
            <div>
              <a
                href="https://www.facebook.com/bfloydtribute/"
                target="_blank"
                aria-label="Facebook"
                rel="noopener"
              >
                <FaFacebook className="icon" size={28} />
              </a>
              <a
                href="https://www.facebook.com/thecrowdcovergroep/"
                target="_blank"
                aria-label="Facebook"
                rel="noopener"
              >
                <FaYoutube className="icon" size={28} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
