import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";

import LoadingButton from "../buttons/loadingButton";
import { TextArea, TextInput } from "../inputs/formInput";

export default function ContactForm() {
  const initValues = { name: "", email: "", company: "", message: "" };

  const handleSubmit = (values, actions) => {
    const mailtoLink = `mailto:info@thecrowd.be?subject=Contact from ${values.name}&body=Name: ${values.name}%0D%0AEmail: ${values.email}%0D%0ACompany: ${values.company}%0D%0AMessage: ${values.message}`;
    window.location.href = mailtoLink;
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form onSubmit={props?.handleSubmit}>
          <Row>
            <Col md={6}>
              <TextInput name="name" placeholder="Naam" />
            </Col>
            <Col md={6}>
              <TextInput name="email" placeholder="E-mail" />
            </Col>
          </Row>
          <Col>
            <TextInput name="company" placeholder="Bedrijf" />
          </Col>
          <Col>
            <TextArea name="message" rows="5" placeholder="Bericht" />
          </Col>
          <LoadingButton
            className="btn secundairy_button"
            text={"Contacteer ons"}
            isLoading={props?.isSubmitting}
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
}

const validation = Yup.object().shape({
  name: Yup.string().required("Naam is verplicht"),
  email: Yup.string()
    .email("Ongeldig e-mailadres")
    .required("E-mail is verplicht"),
  message: Yup.string().required("Bericht is verplicht"),
});
