import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotifySuccess = (message) => {
  toast.success(message);
};

export const NotifyError = (message) => {
  toast.error(message);
};

export const NotifyInfo = (message) => {
  toast.info(message);
};

export const NotifyWarning = (message) => {
  toast.warning(message);
};
