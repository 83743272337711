import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FaFacebook, FaYoutube } from "react-icons/fa";

import ContactForm from "../components/forms/contactForm";

export default function Contact() {
  return (
    <main class="full-height background">
      <Container>
        <div className="d-flex flex-row justify-content-end pt-4 pb-4">
          <h1 className="section_title text-uppercase">{"Contact"}</h1>
        </div>
        <h5 className="custom_card_title text-center mb-3">
          Heeft u een vraag of opmerking?
          <br />
          Contacteer ons via onderstaand formulier!
        </h5>
        <Row noGutters>
          <Col
            className="d-flex flex-column"
            xs={{ span: 12, order: 1 }}
            md={{ span: 4, order: 1 }}
            lg={{ span: 5, order: 1 }}
          >
            <Nav className="list-unstyled d-flex justify-content-center pb-4">
              <div>
                <a
                  href="https://www.facebook.com/bfloydtribute/"
                  target="_blank"
                  aria-label="Facebook"
                  rel="noopener"
                >
                  <FaFacebook className="icon" size={28} />
                </a>
                <a
                  href="https://www.facebook.com/thecrowdcovergroep/"
                  target="_blank"
                  aria-label="Facebook"
                  rel="noopener"
                >
                  <FaYoutube className="icon" size={28} />
                </a>
              </div>
            </Nav>
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 8, order: 2 }}
            lg={{ span: 7, order: 2 }}
          >
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </main>
  );
}
