import React from "react";
import { Container } from "react-bootstrap";

import MediaCard from "../components/cards/mediaCard";
import { Media_ARRAY } from "../constants/arrays";

export default function Media() {
  const images = Media_ARRAY();

  return (
    <main class="full-height background">
      <Container>
        <div className="d-flex flex-row justify-content-end pt-4 pb-4">
          <h1 className="section_title text-uppercase">{"Media"}</h1>
        </div>
        <MediaCard images={images} />
      </Container>
    </main>
  );
}
