import logo from "../images/logo_default.jpg";
import carousel1 from "../images/carousel1.jpg";
import carousel2 from "../images/carousel2.jpg";
import carousel3 from "../images/carousel3.jpg";
import carousel4 from "../images/carousel4.jpg";
import carousel5 from "../images/carousel5.jpg";
import carousel6 from "../images/carousel6.jpg";
import memberClaude from "../images/memberClaude.jpg";
import memberChris from "../images/memberChris.jpg";
import memberJacques from "../images/memberJacques.jpg";
import memberSven from "../images/memberSven.jpg";
import memberStefaan from "../images/memberStefaan.jpg";
import memberFilip from "../images/memberFilip.jpg";
import media1 from "../images/media1.jpg";
import media2 from "../images/media2.jpg";
import media3 from "../images/media3.jpg";
import media4 from "../images/media4.jpg";
import media5 from "../images/media5.jpg";
import media6 from "../images/media6.jpg";
import media7 from "../images/media7.jpg";
import reference1 from "../images/reference1.jpg";
import reference2 from "../images/reference2.jpg";

export const imageProps = {
  logo: {
    src: logo,
    width: "auto",
    height: "auto",
    alt: "logo",
  },
  carousel1: {
    src: carousel1,
    width: "auto",
    height: "auto",
    alt: "Carsousel1",
  },
  carousel2: {
    src: carousel2,
    width: "auto",
    height: "auto",
    alt: "Carsousel2",
  },
  carousel3: {
    src: carousel3,
    width: "auto",
    height: "auto",
    alt: "Carousel3",
  },
  carousel4: {
    src: carousel4,
    width: "auto",
    height: "auto",
    alt: "Carsousel4",
  },
  carousel5: {
    src: carousel5,
    width: "auto",
    height: "auto",
    alt: "Carsousel5",
  },
  carousel6: {
    src: carousel6,
    width: "auto",
    height: "auto",
    alt: "Carousel6",
  },
  memberClaude: {
    src: memberClaude,
    width: "auto",
    height: "auto",
    alt: "memberClaude",
  },
  memberChris: {
    src: memberChris,
    width: "auto",
    height: "auto",
    alt: "memberChris",
  },
  memberJacques: {
    src: memberJacques,
    width: "auto",
    height: "auto",
    alt: "memberJacques",
  },
  memberSven: {
    src: memberSven,
    width: "auto",
    height: "auto",
    alt: "memberSven",
  },
  memberStefaan: {
    src: memberStefaan,
    width: "auto",
    height: "auto",
    alt: "memberStefaan",
  },
  memberFilip: {
    src: memberFilip,
    width: "auto",
    height: "auto",
    alt: "memberFilip",
  },
  media1: {
    src: media1,
    width: "auto",
    height: "auto",
    alt: "media1",
  },
  media2: {
    src: media2,
    width: "auto",
    height: "auto",
    alt: "media2",
  },
  media3: {
    src: media3,
    width: "auto",
    height: "auto",
    alt: "media3",
  },
  media4: {
    src: media4,
    width: "auto",
    height: "auto",
    alt: "media4",
  },
  media5: {
    src: media5,
    width: "auto",
    height: "auto",
    alt: "media5",
  },
  media6: {
    src: media6,
    width: "auto",
    height: "auto",
    alt: "media6",
  },
  media7: {
    src: media7,
    width: "auto",
    height: "auto",
    alt: "media7",
  },
  reference1: {
    src: reference1,
    width: "auto",
    height: "auto",
    alt: "reference1",
  },
  reference2: {
    src: reference2,
    width: "auto",
    height: "auto",
    alt: "reference2",
  },
};
