import React, { useState } from "react";
import { Row, Card, Button } from "react-bootstrap";
import { IoMdClock } from "react-icons/io";
import { FaArrowLeft, FaEdit, FaMapPin, FaTrash } from "react-icons/fa";
import moment from "moment";

import { ConvertTimestampToDate } from "../../fuctions/dates/index";
import OpenOptionsForm from "../forms/openOptionsForm";
import ConcertsForm from "../forms/concertsForm";
import useFirestore from "../../firebase/useFirestore";

export default function ConcertsCard({
  data,
  withSettings,
  isConcertCard,
  isOptionConcertCard,
}) {
  const currentTime = Date.now() / 1000;
  const upcomingConcerts = withSettings
    ? data?.sort((a, b) => a.start_date.seconds - b.start_date.seconds)
    : data
        ?.filter((concert) => concert?.endTime?.seconds > currentTime)
        ?.sort((a, b) => a.start_date.seconds - b.start_date.seconds);

  if (data?.length >= 1) {
    return (
      <Row>
        {upcomingConcerts?.map((item, key) => (
          <React.Fragment key={key}>
            <Concerts
              event={item}
              withSettings={withSettings}
              isConcertCard={isConcertCard}
              isOptionConcertCard={isOptionConcertCard}
            />
          </React.Fragment>
        ))}
      </Row>
    );
  } else {
    return (
      <ul>
        <li>GEEN CONCERTEN GEVONDEN</li>
      </ul>
    );
  }
}

const Concerts = ({
  event,
  withSettings,
  isConcertCard,
  isOptionConcertCard,
}) => {
  const { handleDeleteOption, handleDeleteConcert } = useFirestore();
  const [openCard, setOpenCard] = useState(false);

  const location = event?.location || "-";
  const event_name = event?.name || "-";
  const start_date = event?.start_date
    ? moment(ConvertTimestampToDate(event.start_date)).format("DD-MM-YYYY")
    : "-";
  const time_range = event?.startTime
    ? `${moment(ConvertTimestampToDate(event?.startTime)).format(
        "HH:mm"
      )} - ${moment(ConvertTimestampToDate(event?.endTime)).format("HH:mm")}`
    : "-";
  const sponsor_link = (
    <Button
      variant="primary"
      href={event?.sponsor_link ? event?.sponsor_link : undefined}
      className={`ml-2 ${!event?.sponsor_link ? "hidden_button" : ""}`}
      target="_blank"
      disabled={!event?.sponsor_link}
    >
      INFO
    </Button>
  );

  const dataToSend = {
    id: event?.id,
    name: event?.name,
    location: event?.location,
    sponsor_link: event?.sponsor_link,
    start_date: event?.start_date,
    startTime: event?.startTime,
    endTime: event?.endTime,
  };

  const RemoveItem = async () => {
    isConcertCard
      ? await handleDeleteConcert(dataToSend?.id)
      : isOptionConcertCard && (await handleDeleteOption(dataToSend?.id));
    setOpenCard(false);
  };

  return (
    <Card className="mb-3 concerts_card">
      <Card.Body>
        {openCard ? (
          isOptionConcertCard ? (
            <OpenOptionsForm setOpen={setOpenCard} isChange data={dataToSend} />
          ) : (
            isConcertCard && (
              <ConcertsForm setOpen={setOpenCard} isChange data={dataToSend} />
            )
          )
        ) : (
          <>
            <h4 className="d-flex flex-row justify-content-between align-items-center flex-wrap">
              <div className="d-flex flex-row justify-content-center align-items-center pb-1 concerts_date">
                {start_date}
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center pb-1">
                {sponsor_link}
              </div>
            </h4>
            <h2 className="text-uppercase font-weight-bold concerts_name">
              {event_name}
            </h2>
            <h5 className="d-flex flex-row justify-content-between align-items-center flex-wrap  mb-0 pb-0 pt-3">
              <div className="d-flex flex-row justify-content-center align-items-center pb-1">
                <IoMdClock size={20} className="icon_margin concerts_icon" />
                {time_range}
              </div>
              <div className="d-flex flex-row align-items-center">
                <FaMapPin size={20} className="icon_margin concerts_icon" />
                {location}
              </div>
            </h5>
          </>
        )}
        {withSettings && (
          <>
            <hr />
            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
              <h5>
                {openCard ? (
                  <FaArrowLeft
                    className="icon_change grab"
                    onClick={() => setOpenCard(!openCard)}
                  />
                ) : (
                  <FaEdit
                    className="icon_change grab"
                    onClick={() => setOpenCard(!openCard)}
                  />
                )}
              </h5>
              <h5>
                <FaTrash className="icon_red grab" onClick={RemoveItem} />
              </h5>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
