import React from "react";
import { Card } from "react-bootstrap";

import Logo from "../../images/logo_default.jpg";
import LoginForm from "../forms/loginForm";

export default function LoginCard() {
  return (
    <div className=" d-flex flex-row justify-content-center w-100">
      <Card className="login_card">
        <Card.Body>
          <Card.Img src={Logo} className="img-fluid mb-3" alt="TitleLogo" />
          <LoginForm />
        </Card.Body>
      </Card>
    </div>
  );
}
