import React from "react";
import { Carousel } from "react-bootstrap";

import { Carsousel_HOME_ARRAY } from "../../constants/arrays";
import ProgressiveImage from "../images/progressiveImage";

export default function CustomCarousel({ id }) {
  return (
    <Carousel
      className="carousel-container"
      fade
      interval={4000}
      keyboard
      touch
    >
      {CarouselContent({ id })}
    </Carousel>
  );
}

function CarouselContent({ id }) {
  switch (id) {
    case "home":
      const homeArr = Carsousel_HOME_ARRAY();
      return CarouselItems(homeArr);
    default:
      return null;
  }
}

function CarouselItems(arr) {
  // CANT USE REACT FRAGMENT ON HERE
  return arr?.map((item, key) => {
    return (
      <Carousel.Item key={key}>
        <ProgressiveImage
          className="w-100 carousel-image"
          src={item?.src}
          alt={item?.alt}
          height={item?.height}
          width={item?.width}
        />
      </Carousel.Item>
    );
  });
}
