export const ConvertTimestampToDate = (timestamp) => {
  return new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000);
};

export const FormatTimestamp = (timestamp, type) => {
  if (!timestamp || !timestamp?.seconds) return "";

  const date = new Date(timestamp?.seconds * 1000);

  if (type === "date") {
    return date.toISOString().split("T")[0]; // Returns 'YYYY-MM-DD'
  } else if (type === "time") {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`; // Returns 'HH:MM'
  }

  return "";
};
