import React from "react";
import { Button, Container } from "react-bootstrap";

import ConcertsCard from "../components/cards/concertsCard";
import useFirestore from "../firebase/useFirestore";

export default function Concerts() {
  const { concerts } = useFirestore();

  return (
    <main class="full-height background">
      <Container>
        <div className="d-flex flex-row justify-content-end pt-4 pb-4">
          <h1 className="section_title text-uppercase">{"Concerts"}</h1>
        </div>
        <h5 className="custom_card_title text-center">
          {`Voor details en een eventuele tickets bij een betalend concert, klik op
            de knop `}
          <Button variant="primary" className="ml-2">
            INFO
          </Button>
        </h5>
        <ConcertsCard data={concerts} />
      </Container>
    </main>
  );
}
