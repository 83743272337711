import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { imageProps } from "../../constants/imageProps";

function ProgressiveImage({
  src,
  className,
  alt,
  width,
  height,
  onLoad,
  style,
  onError,
  isCardImage,
}) {
  const imgSrc = src || imageProps?.logo?.src;
  const imgAlt = alt || "IMAGE";

  if (isCardImage) {
    return (
      <InnerImageZoom
        src={imgSrc}
        alt={imgAlt}
        className={className}
        zoomScale={0.25}
        zoomSrc={imgSrc}
        zoomPreload
        zoomType="hover"
        moveType="pan"
        hideHint
        hideCloseButton
      />
    );
  }

  return (
    <LazyLoadImage
      src={imgSrc}
      alt={imgAlt}
      className={className}
      width={width || "auto"}
      effect="opacity"
      visibleByDefault
      threshold={100}
      debounce={250}
      height={height || "auto"}
      onLoad={onLoad}
      onError={onError}
      style={style}
    />
  );
}

export default ProgressiveImage;
