import React from "react";
import { Container } from "react-bootstrap";

import TextImgCard from "../components/cards/textImgCard";
import { Members_ARRAY } from "../constants/arrays";

export default function Members() {
  const membersArr = Members_ARRAY();

  return (
    <main className="full-height background">
      <Container>
        <div className="d-flex flex-row justify-content-end pt-4 pb-4">
          <h1 className="section_title text-uppercase">{"Members"}</h1>
        </div>
        <TextImgCard arr={membersArr} />
      </Container>
    </main>
  );
}
