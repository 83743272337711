import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import useFirestore from "../../firebase/useFirestore";
import { FormatTimestamp } from "../../fuctions/dates";

import LoadingButton from "../buttons/loadingButton";
import { DateInput, TextInput, TimeInput } from "../inputs/formInput";

export default function ConcertsForm({ isChange, setOpen, data }) {
  const { handleAddConcert, handleUpdateConcert } = useFirestore();

  const initValues = {
    name: data ? data?.name : "",
    location: data ? data?.location : "",
    sponsor_link: data ? data?.sponsor_link : "",
    start_date: data ? FormatTimestamp(data?.start_date, "date") : "",
    startTime: data ? FormatTimestamp(data?.startTime, "time") : "",
    endTime: data ? FormatTimestamp(data?.endTime, "time") : "",
  };

  const handleSubmit = async (values, actions) => {
    isChange
      ? await handleUpdateConcert(data?.id, values)
      : await handleAddConcert(values);
    actions.setSubmitting(false);
    setOpen(false);
    actions.resetForm();
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Row>
          <Card className="concerts_card">
            <Card.Body>
              <h4 className="pb-0 pt-0 mt-0 mb-3 text-center">
                {isChange ? "Concert wijzigen" : "Concert toevoegen"}
              </h4>
              <Form onSubmit={props?.handleSubmit}>
                <Row>
                  <Col md={12}>
                    <TextInput name="name" placeholder="Naam" />
                  </Col>
                  <Col md={12}>
                    <TextInput
                      name="location"
                      placeholder="Locatie van het optreden"
                    />
                  </Col>
                  <Col md={12}>
                    <TextInput
                      name="sponsor_link"
                      placeholder="Link naar ticketverkoop (exact path invullen: https://...)"
                    />
                  </Col>
                  <Col md={4}>
                    <DateInput
                      name="start_date"
                      placeholder="Datum van het optreden"
                    />
                  </Col>
                  <Col md={4}>
                    <TimeInput
                      name="startTime"
                      placeholder="Starttijd van het optreden"
                    />
                  </Col>
                  <Col md={4}>
                    <TimeInput
                      name="endTime"
                      placeholder="Starttijd van het optreden"
                    />
                  </Col>
                </Row>
                <div className="d-flex">
                  <LoadingButton
                    className="btn form_button"
                    text={isChange ? "Wijzigen" : "Toevoegen"}
                    isLoading={props?.isSubmitting}
                    type="submit"
                  />
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Row>
      )}
    </Formik>
  );
}

const validation = Yup.object().shape({
  name: Yup.string().required("Naam is verplicht"),
  location: Yup.string().required("Locatie is verplicht"),
  start_date: Yup.date().required("Datum is verplicht").nullable(),
  startTime: Yup.string()
    .required("Starttijd is verplicht")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Ongeldige tijd"),
  endTime: Yup.string()
    .required("Starttijd is verplicht")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Ongeldige tijd"),
});
