import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";

import ConcertsCard from "../components/cards/concertsCard";
import ConcertsForm from "../components/forms/concertsForm";
import OpenOptionsForm from "../components/forms/openOptionsForm";
import useFirestore from "../firebase/useFirestore";

export default function Dashboard() {
  const { concerts, options } = useFirestore();

  const [openOptions, setOpenOptions] = useState(false);
  const [openConcerts, setOpenConcerts] = useState(false);

  return (
    <main class="full-height background">
      <Container>
        <div className="d-flex flex-row justify-content-end pt-4 pb-4">
          <h1 className="section_title text-uppercase">{"Dashboard"}</h1>
        </div>
      </Container>
      <Container>
        <div className="d-flex flex-row justify-content-between align-items-center pt-4 pb-4">
          <h2 className="text-uppercase section_content_title">
            {"Open opties voor concerten"}
          </h2>
          <h4>
            {openOptions ? (
              <FaMinusCircle
                className="icon_red grab"
                onClick={() => setOpenOptions(!openOptions)}
              />
            ) : (
              <FaPlusCircle
                className="icon_green grab"
                onClick={() => setOpenOptions(!openOptions)}
              />
            )}
          </h4>
        </div>
        {openOptions ? (
          <OpenOptionsForm setOpen={setOpenOptions} />
        ) : (
          <ConcertsCard withSettings data={options} isOptionConcertCard />
        )}
      </Container>
      <Container>
        <div className="d-flex flex-row justify-content-between align-items-center pt-4 pb-4">
          <h2 className="text-uppercase section_content_title">
            {"Concerts (live website)"}
          </h2>
          <h4>
            {openConcerts ? (
              <FaMinusCircle
                className="icon_red grab"
                onClick={() => setOpenConcerts(!openConcerts)}
              />
            ) : (
              <FaPlusCircle
                className="icon_green grab"
                onClick={() => setOpenConcerts(!openConcerts)}
              />
            )}
          </h4>
        </div>
        {openConcerts ? (
          <ConcertsForm setOpen={setOpenConcerts} />
        ) : (
          <ConcertsCard withSettings data={concerts} isConcertCard />
        )}
      </Container>
    </main>
  );
}
