import React, { useEffect } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { useLocation, Navigate } from "react-router";

import HeaderNav from "./components/navbar/headernav";
import Home from "./pages";
import Login from "./pages/login";
import Members from "./pages/members";
import Concerts from "./pages/concerts";
import Media from "./pages/media";
import References from "./pages/references";
import Contact from "./pages/contact";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Footer from "./components/footer";
import { useAuth } from "./firebase/useAuth";
import Dashboard from "./pages/dashboard";

function NavBarRoutes() {
  return (
    <>
      <HeaderNav />
      <Outlet />
      <Footer />
    </>
  );
}

function ProtectedRoutes() {
  const { user } = useAuth();
  const location = useLocation();

  switch (user) {
    case undefined:
      return null;
    default:
      return user ? (
        <Outlet />
      ) : (
        <Navigate to={"/login"} replace state={{ path: location?.pathname }} />
      );
  }
}

function LoginRoutes() {
  const { user } = useAuth();
  const location = useLocation();

  return user ? (
    <Navigate to={"/dashboard"} replace state={{ path: location?.pathname }} />
  ) : (
    <Outlet />
  );
}

export default function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route element={<NavBarRoutes />}>
        <Route path={"/"} element={<Home />} />
        <Route path={"/members"} element={<Members />} />
        <Route path={"/concerts"} element={<Concerts />} />
        <Route path={"/media"} element={<Media />} />
        <Route path={"/references"} element={<References />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route element={<LoginRoutes />}>
          <Route path={"/login"} element={<Login />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Route>
    </Routes>
  );
}
