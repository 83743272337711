import React from "react";
import { Card } from "react-bootstrap";

import ProgressiveImage from "../images/progressiveImage";

export default function TextImgCard({ arr }) {
  return arr.map((item, index) => (
    <Card key={index} className="mb-3 horizontal_card">
      <Card.Body>
        <h2
          className={`card_title ${
            index % 2 === 0 ? "text-start" : "text-end"
          }`}
        >
          {item?.title}
        </h2>
        <ProgressiveImage
          src={item?.img}
          className={`titleImg img-fluid ${
            index % 2 === 0
              ? "float-left left_img_mg"
              : "float-right right_img_mg"
          }`}
        />
        <p className={`${index % 2 === 0 ? "text-start" : "text-start"}`}>
          {item?.text}
        </p>
      </Card.Body>
    </Card>
  ));
}
