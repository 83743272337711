import { useState, useEffect } from "react";
import {
  onConcertsChange,
  onConcertOptionsChange,
  addConcert,
  updateConcert,
  deleteConcert,
  addConcertOption,
  updateConcertOption,
  deleteConcertOption,
} from "./firestoreUtils";

const useFirestore = () => {
  const [concerts, setConcerts] = useState([]);
  const [options, setOptions] = useState([]);

  const handleAddConcert = async (values) => {
    await addConcert(values);
  };

  const handleUpdateConcert = async (id, updatedConcert) => {
    await updateConcert(id, updatedConcert);
  };

  const handleDeleteConcert = async (id) => {
    await deleteConcert(id);
  };

  const handleAddOption = async (values) => {
    await addConcertOption(values);
  };

  const handleUpdateOption = async (id, updatedOption) => {
    await updateConcertOption(id, updatedOption);
  };

  const handleDeleteOption = async (id) => {
    await deleteConcertOption(id);
  };

  useEffect(() => {
    const unsubscribeConcerts = onConcertsChange(setConcerts);
    const unsubscribeOptions = onConcertOptionsChange(setOptions);

    return () => {
      unsubscribeConcerts();
      unsubscribeOptions();
    };
  }, []);

  return {
    concerts,
    options,
    handleAddConcert,
    handleUpdateConcert,
    handleDeleteConcert,
    handleAddOption,
    handleUpdateOption,
    handleDeleteOption,
  };
};

export default useFirestore;
