import React from "react";
import YouTube from "react-youtube";

export default function YoutubePlayer({ videoId }) {
  const opts = {
    height: "600px",
    width: "100%",
    playerVars: {
      fs: 1,
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} allowFullScreen />;
}
