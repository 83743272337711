import React from "react";
import { Container } from "react-bootstrap";

import LoginCard from "../components/cards/loginCard";

export default function Login() {
  return (
    <main class="full-height">
      <Container>
        <LoginCard />
      </Container>
    </main>
  );
}
