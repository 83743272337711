import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../firebase/useAuth";

import LoadingButton from "../buttons/loadingButton";
import { TextInput } from "../inputs/formInput";

export default function LoginForm() {
  const { signInUser } = useAuth();

  const initValues = { email: "", password: "" };

  const handleSubmit = async (values, actions) => {
    await signInUser(values?.email, values?.password);
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form onSubmit={props?.handleSubmit}>
          <TextInput isEmail name="email" placeholder="E-mail" />
          <TextInput isPassword name="password" placeholder="Wachtwoord" />
          <LoadingButton
            className="btn w-100 login_button"
            text={"Aanmelden"}
            isLoading={props?.isSubmitting}
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
}

const validation = Yup.object().shape({
  email: Yup.string()
    .email("Ongeldig e-mailadres")
    .required("E-mail is verplicht"),
  password: Yup.string().required("Wachtwoord is verplicht"),
});
