import React from "react";
import { Container } from "react-bootstrap";

import CustomCarousel from "../components/carousel";
import ProgressiveImage from "../components/images/progressiveImage";
import YoutubePlayer from "../components/youtube";
import Logo from "../images/logo_default.jpg";

export default function Home() {
  return (
    <>
      <CustomCarousel id="home" />
      <main className="full-height background">
        <Container>
          <div className="pb-4 d-flex flex-row justify-content-center align-items-center">
            <div className="flex-column flex-fill align-items-center">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <ProgressiveImage
                  src={Logo}
                  className="carousel_logo pt-4 img-fluid"
                  alt="TitleLogo"
                />
              </div>
              <p className="text-center">
                Zes gepassioneerde en doorwinterde muzikanten met jarenlange
                podiumervaring ontdekken dat ze al jaren stiekem een
                gezamenlijke passie koesteren: de tijdloze muziek van Pink Floyd
                ! De koppen werden bij elkaar gestoken en plannen gesmeed. Het
                basisidee groeide, al vrij snel werden de eerste repetities
                ingepland en na een intense periode van hard werken is de band
                sinds kort podiumrijp. <b>B-Floyd</b> is geboren!
              </p>
              <p className="text-center">
                <b>B-Floyd</b> brengt op passende wijze hulde aan de muziek van
                Pink Floyd en neemt u mee op een reis door hun wondere wereld.
                Laat u onderdompelen in de magische sfeer en herbeleef de
                onvergetelijke songs van klassiekers uit o.a. “The Wall” en
                “Dark Side of the Moon”. Wij kijken ernaar uit om u op een van
                onze concerten te mogen ontmoeten en onze muzikale dromen met u
                te mogen delen.
              </p>
              <p>
                <div className="text-center pb-2">
                  <b>B-Floyd</b> is:
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-md-6 ">
                    <ul class="mb-0">
                      <li>Claude Delatter - Zang</li>
                      <li>Chris Somers - Gitaren</li>
                      <li>
                        {"Jacques 'James' Audenaert - Drums & achtergrondzang"}
                      </li>
                      <li>{"Sven Godyn – Keyboards & Visuals"}</li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 col-md-6 ">
                    <ul class="pt-0">
                      <li>
                        {"Stefaan Vansteenkiste – Gitaren & achtergrondzang"}
                      </li>
                      <li>{"Filip Vanderputten - Bas"}</li>
                    </ul>
                  </div>
                </div>
              </p>
            </div>
          </div>
          <YoutubePlayer videoId="wgDGIB1XmwQ" />
        </Container>
      </main>
    </>
  );
}
