import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCaO8iSDfLo1uowKL2F1v5QDjsfnjf8Emk",
  authDomain: "bfloyd-a10df.firebaseapp.com",
  projectId: "bfloyd-a10df",
  storageBucket: "bfloyd-a10df.appspot.com",
  messagingSenderId: "455604070806",
  appId: "1:455604070806:web:ea61e5c7dc05e933a155b7",
  measurementId: "G-5M274KRWN9",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics };
