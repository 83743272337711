import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./firebaseConfig";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import {
  NotifyError,
  NotifySuccess,
} from "../components/notifications/toastify";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // SIGN IN USER
  const signInUser = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUser(user);
      NotifySuccess("Welkom terug!");
    } catch (e) {
      NotifyError(e?.code);
    }
  };

  // SIGN OUT USER
  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
      NotifySuccess("Tot ziens!");
    } catch (e) {
      NotifyError(e?.code);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const value = {
    user,
    setUser,
    signInUser,
    signOutUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
