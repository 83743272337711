import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaRegUser, FaSignOutAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../firebase/useAuth";

import NavLogo from "../../images/navLogo.png";
import ProgressiveImage from "../images/progressiveImage";

export default function HeaderNav() {
  const { user, signOutUser } = useAuth();

  const [expanded, setExpanded] = useState(false);

  const handleSignOut = async () => {
    await signOutUser();
  };

  return (
    <Navbar
      collapseOnSelect
      expanded={expanded}
      expand="lg"
      className="navbar-custom"
      sticky="top"
      bg="dark"
      variant="dark"
    >
      <Container>
        <Navbar.Brand>
          <ProgressiveImage
            src={NavLogo}
            width="auto"
            height="55"
            className="p-3"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              end={true}
              to="/"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Home
            </NavLink>
            <NavLink
              end={true}
              to="/members"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Members
            </NavLink>
            <NavLink
              end={true}
              to="/concerts"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Concerts
            </NavLink>

            <NavLink
              end={true}
              to="/media"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Media
            </NavLink>
            <NavLink
              end={true}
              to="/references"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              References
            </NavLink>
            <NavLink
              end={true}
              to="/contact"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Contact
            </NavLink>
          </Nav>
          <Nav>
            <NavLink
              end={true}
              to={user ? "/dashboard" : "/login"}
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              {user ? <FaRegUser /> : "Login"}
            </NavLink>
            {user && (
              <button onClick={handleSignOut} className="nav-link no_btn_style">
                <FaSignOutAlt />
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
