import React from "react";
import { Container } from "react-bootstrap";
import ReferenceCard from "../components/cards/referenceCard";
import { Reference_ARRAY } from "../constants/arrays";

export default function References() {
  const images = Reference_ARRAY();

  return (
    <main class="full-height background">
      <Container>
        <div className="d-flex flex-row justify-content-end pt-4 pb-4">
          <h1 className="section_title text-uppercase">{"References"}</h1>
        </div>
        <ReferenceCard images={images} />
      </Container>
    </main>
  );
}
